import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from './FireworksAnimation.json';

// A fun sparkles animation shown upon pangram discovery.
// Src: https://lottiefiles.com/free-animation/sparkles-burst-nmVUWtP04d.
const PangramAnimation = () => {
  return (
    <Lottie
      loop
      animationData={animationData}
      play
      style={{ width: 300, height: 300 }}
    />
  );
};

export default PangramAnimation;
