function assert(expr: unknown, msg?: string): asserts expr {
  if (!expr) throw new Error(msg);
}

// TODO: migrate SpellingCee.js to getWordsInternal after investigating
// React state.
function getWords(letters: string, dictionary: string[]): string[] {
  if (letters === null) {
    return [];
  }
  if (letters.length < 7) {
    return [];
  }
  return getWordsInternal(letters[0], letters.slice(1), dictionary);
}

// getWordsInternal returns all words in `dictionary` that contain `requiredLetter` 1+ times and whose all
// other letters come from `permissableLetters`
function getWordsInternal(requiredLetter: string, permissableLetters: string, dictionary: string[]): string[] {
  assert(requiredLetter.length === 1, 'only 1 letter is required');

  const letterSet = new Set(permissableLetters.split(''));
  letterSet.add(requiredLetter);
  const words = dictionary.filter((word) => {
    const hasRequiredLetter = word.indexOf(requiredLetter) > -1;
    if (!hasRequiredLetter) {
      return false;
    }

    return [...word].every((letter) => letterSet.has(letter));
  });

  return words;
}

// wordContainsAll checks that all of `letters` appears in `word`.
// Does not check if `word` has characters not in `letters`.
function wordContainsAll(word: string, letters: string[]): boolean {
  function oneLetterOnly(x: string) {
    assert(x.length === 1, 'should be 1 character');
  }
  letters.forEach(oneLetterOnly);

  const lowerWord = word.toLowerCase();
  const lowerLetters = letters.map((letter) => letter.toLowerCase());

  return lowerLetters.every((letter) => lowerWord.includes(letter));
}

function scoreWords(words: string[], letters: string) {
  return words.reduce((accumulator, word: string) => {
    var points = 0
    if (word.length < 4) {
      return 0
    }
    if (word.length === 4) {
      points = 1;
    } else if (word.length > 4) {
      points = word.length;
    }
    // console.log(`Word is ${word} and accumulated points are ${accumulator + points}`)
    const bonus = wordContainsAll(word, letters.split('')) ? 7 : 0;
    return accumulator + points + bonus;
  }, 0);
}

function shuffle(array: Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function shuffleNonAnchoredLetters(letters: string) {
  // Always keep the first letter in place.
  return letters[0] + shuffle(Array.from(letters.substring(1))).join('');
}

function getPuzzle(dictionary: string[]) {
  type LetterSetCounts = {
    [key: string]: number;
  };
  const letterSetCounts: LetterSetCounts = {};

  dictionary.forEach((word) => {
    // Filter out words with the letter `s`: https://nytbee.com/.
    if (word.indexOf('s') === -1) {
      const letterSet = new Set(word);
      if (letterSet.size === 7) {
        const letters = [...letterSet].sort().join('');
        letterSetCounts[letters] = (letterSetCounts[letters] ?? 0) + 1;
      }
    }
  });

  if (Object.keys(letterSetCounts).length === 0) {
    return null;
  }

  const puzzles = Object.keys(letterSetCounts).filter((letters) => {
    return letterSetCounts[letters] === 1;
  });

  if (puzzles.length === 0) {
    return null;
  }

  var wordCount = 0;
  var puzzle = null;
  var count = 0;
  // Find a puzzle with a word count between 18 and 44 is found, or until 20 attempts have been made.
  while ((wordCount < 18 || wordCount > 44) && count < 20) {
    let i = Math.floor(Math.random() * puzzles.length);
    puzzle = shuffle(puzzles[i].split('')).join('');
    //console.log(`Trying this puzzle: ${puzzle}`)
    wordCount = getWordsInternal(puzzle[0], puzzle.slice(1), dictionary).length;
    //console.log(`has this many words: ${wordCount}`)
    count += 1;
  }

  console.log('Puzzle found: ' + puzzle);
  console.log('Puzzle has: ' + wordCount + ' words');
  return puzzle;
}

function getRatingLevels(maxScore: number) {
  return { 'queenBee': maxScore,
           'genius': Math.ceil(0.7 * maxScore),
           'amazing': Math.ceil(0.5 * maxScore),
           'great': Math.ceil(0.4 * maxScore),
           'nice': Math.ceil(0.25 * maxScore),
           'solid': Math.ceil(0.15 * maxScore),
           'good': Math.ceil(0.1 * maxScore),
           'movingUp': Math.ceil(0.05 * maxScore),
           'goodStart': Math.ceil(0.02 * maxScore)
  };
}

function getRating(userScore: number, maxScore: number): string {
  const percent = userScore / maxScore;
  if (percent >= 0.99)
    return "Grand Poobah!"
  else if (percent >= 0.7)
    return "Genius"
  else if (percent >= 0.5)
    return "Awesome"
  else if (percent >= 0.4)
    return "Superb"
  else if (percent >= 0.25)
    return "Nifty"
  else if (percent >= 0.15)
    return "Satisfactory"
  else if (percent >= 0.10)
    return "Promising"
  else if (percent >= 0.05)
    return "Adequate"
  else if (percent >= 0.02)
    return "Getting there"
  else return "Newbie"
}

export {
  getRatingLevels,
  getRating,
  getWords,
  wordContainsAll,
  scoreWords,
  getPuzzle,
  shuffleNonAnchoredLetters,
  getWordsInternal,
};
