import React from "react";
import './Input.scss';


const Input = (props) => { 

  return (
    <div id="Input">
      <div className="cursor" onKeyPress={() => props.onKeyPress}>
        <span>
          {props.word}
        </span>
        <div className="blink">

        </div>
        <i></i>
      </div>
    </div>
  );
}

export default Input;